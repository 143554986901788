import React, { useEffect, ReactNode } from 'react';
import { useLocation } from '@reach/router';

interface GlobalContentTransformerProps {
  children: ReactNode;
}

const GlobalContentTransformer: React.FC<GlobalContentTransformerProps> = ({
  children,
}) => {
  const location = useLocation();

  useEffect(() => {
    // Replace all occurrences of 'Empwr' with 'EMPWR'
    const replaceTextContent = (node: Node) => {
      if (node.nodeType === 3) {
        node.textContent = node.textContent?.replace(/Empwr/g, 'EMPWR') ?? '';
      } else if (node.nodeType === 1) {
        node.childNodes.forEach(replaceTextContent);
      }
    };

    document.body.childNodes.forEach(replaceTextContent);
  }, [location.pathname]);

  return <>{children}</>;
};

export default GlobalContentTransformer;
