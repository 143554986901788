/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

import './src/styles/global.css';
import './src/styles/network.css';
import './src/styles/search.css';
import '@fontsource-variable/inter';
import React from 'react';
import { RootElement } from 'components/rootElement';
import { Script } from 'gatsby';
import { FeatheryFormProvider } from './src/context/featheryFormContext';
import GlobalContentTransformer from './src/components/GlobalContentTransformer';

const googleTagScript = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${process.env.GATSBY_EMPWR_GTM_GTAG_ID}');`;

export const wrapPageElement = ({ element, props }) => {
  if (props.path.includes('network') || props.path.includes('contact')) {
    return (
      <>
        <GlobalContentTransformer>
          <RootElement>
            <FeatheryFormProvider>{element}</FeatheryFormProvider>
          </RootElement>
        </GlobalContentTransformer>
        <Script id='google-tag-id'>{googleTagScript}</Script>
      </>
    );
  }
  return (
    <>
      <GlobalContentTransformer>
        <RootElement>{element}</RootElement>
      </GlobalContentTransformer>
      <Script id='google-tag-id'>{googleTagScript}</Script>
    </>
  );
};

export const shouldUpdateScroll = ({ routerProps: { location } }) => {
  const { pathname } = location;
  // list of routes for the scroll-to-top-hook
  const scrollToTopRoutes = [`/chat/`];
  // if the new route is part of the list above, scroll to top (0, 0)
  if (scrollToTopRoutes.indexOf(pathname) !== -1) {
    window.scrollTop = 0;
    return false;
  }

  return true;
};
